<template>
	<section class="views">
		<!-- header -->
		<headers select="-1"></headers>

		<!-- banner  -->
		<div class="posters">
			<van-image width="100%" :src="require('@/assets/image/hodgepodge/pcbanner.png')" />
			<div class="posters_ewm" >
				<div class="ewm" @click="whatapp()">
				<div class="ewm2">
					<div class="ewm3">
					<img :src="require('@/assets/image/hodgepodge/ewm.png')" alt="" />
					</div>
					<div class="ewm3_value"> <img :src="require('@/assets/image/wa.png')" alt=""> 人工諮詢</div>
				</div>
				</div>
			</div>
		</div>

		<!-- from -->
		<div class="content">
			<div class="contentFrom_titleBox">
				<div class="contentFrom_title">報名資料</div>
			</div>
			<div class="contentFrom_uls">
				<ul>
					<li>
						<div class="list2">
							<div class="list2_uls">
								<div class="listLeft">參賽者姓名</div>
								<div class="listRight">
									<!-- *須與身份證／學生證上的姓名一致 -->
									<input type="text" placeholder="請輸入參賽者姓名" v-model.trim="from.username" />
								</div>
							</div> 

							<div class="beizhu">*須與身份證／學生證上的姓名一致</div>
						</div>
					</li>
					<li>
						<div class="list">
							<div class="listLeft">性别</div>
							<div class="listRight">
								<!-- @click="sexBut" -->
								<div class="listRight_xuan">
									<!-- 	<span :class="
                      from.sex == '請選擇參賽者性别' ? '' : 'placeholderValue'
                    ">{{ this.from.sex }}</span>
									<span class="listRight_icon"
										:class="{ '': true, listRight_icontransform: showPicker }">
										<van-icon name="arrow-down" size="12" color="#333333" />
									</span>
									 -->

									<van-popover v-model="showSexPopover" trigger="click" :actions="sexActions"
										@select="onSexSelect">
										<template #reference>
											<div class="promptInputValue" :class="sexIndex == -1 ? '' : 'placeholderValue'">
												{{ from.sex  }}</div>
										</template>
									</van-popover>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="list">
							<div class="listLeft">手機號</div>
							<div class="listRight">
								<div class="listRight_sji">
									<div class="left" style="cursor: pointer;"> 
										<van-popover v-model="mobilePhoneActionsShow" placement="bottom" trigger="click" :actions="mobilePhoneActions" @select="onmobilePhoneSelect">
											<template #reference>
												<div class="promptInputValue" :class="gradeIndex == -1 ? '' : 'placeholderValue'">{{ from.code }} <span :class="{ '': true, left_formTwo: showPickerTwo }">
											<van-icon name="arrow-down" size="12" color="#333333" />
										</span></div>
											</template>
										</van-popover>

										
									</div> 

									<div class="right">
										<input type="number" placeholder="請輸入手機號碼" v-model.trim="from.mobilePhone" />
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">驗證碼</div>
							<div class="listRight">
								<div class="listRight_sji2">
									<div class="right">
										<input type="text" placeholder="請輸入驗證碼" v-model.trim="from.mobileCode" />
									</div>
									<div class="right2" @click="CodeBln ? clickNumberCodeFnc() : ''" v-html="codeMsg">
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">電郵</div>
							<div class="listRight" style="display: flex;">
								<input type="text" placeholder="請輸入參賽者電郵" v-model.trim="from.mail" />
							</div>
						</div>
					</li>

					<li>
						<div class="list2">
							<div class="list2_uls">
								<div class="listLeft">年級</div>
								<div class="listRight">
									<div class="listRight_xuan" >
										<!-- @click="schoolBut" -->
										<!-- <span :class="
                        from.grade == '選擇參賽者年級' ? '' : 'placeholderValue'
                      ">{{ this.from.grade }}</span>
										<span class="listRight_icon"
											:class="{'': true,listRight_icontransform: showPickerthree,}">
											<van-icon name="arrow-down" size="12" color="#333333" />
										</span> -->
										
										<van-popover v-model="showGradePopover" placement="right" trigger="click" :actions="gradeActions" @select="onGradeSelect">
											<template #reference>
												<div class="promptInputValue" :class="gradeIndex == -1 ? '' : 'placeholderValue'">{{ from.grade }}</div>
											</template>
										</van-popover>
									</div>
								</div>
							</div>

							<div class="beizhu">*以9月新學年級報名為準<br>*選擇後即代表報名對應參賽組別</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">學校所在地區</div>
							<div class="listRight">
								<div class="listRight_xuan" @click.stop="toggleElement" > 
									<span :class="newAddress.length == 0 ? '' : 'placeholderValue' "
										v-if="newAddress.length != 0 ">{{newAddress[0]}}>{{ newAddress[1]}}</span>
									<span v-else>請選擇地區</span> 
								</div>
								
								<div class="selectBox" v-if="showPickerschool"  ref="elementDQ"> 
									<div class="selectBoxLeft">
										<div class="selectBox_uls">
											<div class="selectBox_lis"  v-for="(item,index) in diquaddRess" :key="index" :class="dqiSelectIndex==index ? 'select_select' : ''" @click="onselectDqBut(item,index)"> {{item.title}} </div> 
										</div>
									</div>
									<div class="selectBox_xian"></div>
									<div class="selectBoxRight">
										<div class="selectBox_uls">
											<div class="selectBox_lis" v-for="(item,index) in diquaddRess2" :key="index" :class="dqiSelectIndex2 == index ? 'select_select' : ''" @click="onselectDqBut2(item,index)" >{{item}}</div> 
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">就讀學校</div>
							<div class="listRight listPan" style="display: flex;">
								<input type="text" placeholder="請輸入參賽者就讀學校" v-model.trim="from.schoolName" />

								<div class="posText" v-if="schoolNameList.length != 0">
									<ul>
										<li v-for="(item,index) in schoolNameList" :key="index" @click="zhiyin(item)"
											v-if="index < 10" style="font-size: 14px;">{{ item. schoolName}}</li>
									</ul>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="uls_beizhu">*如團體機構參賽，請通過［人工諮詢］聯繫我們報名參賽。</div>
			</div>

			<!-- <div class="fromButtom" @click="Frombuttom">
				<img :src="require('@/assets/image/hodgepodge/tjbm.png')" alt="" />
				
			</div> -->
			<div class="timeUp"><div>報名已結束</div></div>
		</div>

		<!-- 展位 -->
		<div style="width:100%;"></div>

		<!-- 性别 -->
		<van-popup v-model="showPicker" position="bottom"
			:style="{ 'min-height': '30%','max-width':'944px','margin':'auto','right': '0','left': '0' }">
			<van-picker show-toolbar :columns="sexColumns" @confirm="onSexConfirm" @cancel="showPicker = false"
				confirm-button-text="確認" />
		</van-popup>
		<!-- code -->
		<van-popup v-model="showPickerTwo" position="bottom"
			:style="{ 'min-height': '30%','max-width':'944px','margin':'auto','right': '0','left': '0' }">
			<van-picker show-toolbar :columns="mobilePhoneColumns" @confirm="onPhoneConfirm"
				@cancel="showPickerTwo = false" confirm-button-text="確認" />
		</van-popup>
		<!-- 年级 -->
		<van-popup v-model="showPickerthree" position="bottom"
			:style="{ 'min-height': '30%','max-width':'944px','margin':'auto','right': '0','left': '0' }">
			<van-picker show-toolbar :columns="gradeColumns" @confirm="ongradeConfirm" @cancel="showPickerthree = false"
				confirm-button-text="確認" />
		</van-popup>
		<!-- <van-popup v-model="showPickerschool2" position="bottom"
			:style="{ 'min-height': '30%','max-width':'944px','margin':'auto','right': '0','left': '0' }">
			<van-picker show-toolbar :columns="diqu" @cancel="showPickerschool2 = false" @change="onAddressChange"
				@confirm="onAddressConfirm" confirm-button-text="確認" />
		</van-popup> -->


	</section>
</template>

<script>
	import headers from "@/components/header/pc_header";
	import selectBox from "./components/select"
	import publics from '@/mixin/public_mixin';
	import home from '@/mixin/home_mixin';
	export default {
		name: "signup",
		data() {
			return {
				// new
				showSexPopover: false,
				sexActions: [{
					text: '男',
					id: 0
				}, {
					text: '女',
					id: 1
				}],
				sexIndex: -1,
				showGradePopover:false,
				gradeActions: [
					{ text: 'K1', id: 0 },
					{ text: 'K2', id: 1 },
					{ text: 'K3', id: 2 },
					{ text: 'P1', id: 3 },
					{ text: 'P2', id: 4 },
					{ text: 'P3', id: 5 },
					{ text: 'P4', id: 6 },
					{ text: 'P5', id: 7 },
					{ text: 'P6', id: 8 },
					{ text: 'S1', id: 9 },
					{ text: 'S2', id: 10 },
					{ text: 'S3', id: 11 }, 
				],
				gradeIndex:-1,
				
				dqiSelectIndex:-1,//默認0
				dqiSelectIndex2:-1,//默認不選擇

				mobilePhoneActionsShow:false,
				mobilePhoneActions: [{
					text: '+852',
					id: 0
				}, {
					text: '+86',
					id: 1
				}],
				
				
				showPickerschool: false,
				diqu: [],
				cities: {},
				from: {
					username: "",
					sex: "請選擇參賽者性别", //-1 未选中  0男  1女
					code: "+852", //区号
					mobilePhone: "", //手机号
					mobileCode: "", //手机验证码
					grade: "選擇參賽者年級", //年级
					mail: "", //邮件
					school_address: "", //地区
					schoolName: "", //就读学校
				},
				showPicker: false,
				showPickerTwo: false,
				showPickerthree: false,
				gradeColumns: [
					"選擇參賽者年級",
					'K1',
					"K2",
					"K3",
					"P1",
					"P2",
					"P3",
					"P4",
					"P5",
					"P6",
					"S1",
					"S2",
					"S3",
				], //年级
				groups: [{
						code: 1,
						name: '幼稚園初級組（K1-K2）'
					},
					{
						code: 2,
						name: '幼稚園高級組（K3）'
					},
					{
						code: 3,
						name: '小學初級組（P1-P2）'
					},
					{
						code: 4,
						name: '小學中級組（P3-P4）'
					},
					{
						code: 5,
						name: '小學高級組（P5-P6）'
					},
					{
						code: 6,
						name: '初中組（S1-S3）'
					}
				],
				competitionGroup: '',
				groupId: '',

				sexColumns: ["請選擇參賽者性别", "男", "女"],
				mobilePhoneColumns: ["+852", "+86"], //区号

				CodeBln: true,
				timer: null, //清空时间
				countdown: 60,
				codeMsg: '獲取驗證碼',

				newAddress: [], //地區
				schoolNameList: [], //提示  
				schoolNameShow: true, //點擊
				diquaddRess:[],//原始
				diquaddRess2:[],
			};
		},
		computed: {},
		mixins: [publics,home],
		components: {
			headers,
			selectBox
		},
		watch: {
			newAddress(val) {
				if (val) {
					console.log(val)
					this.schoolNameList = [];
					this.from.schoolName = '';
				}
			},
			"from.schoolName": {
				immediate: true,
				handler(val) {
					console.log(val,val.length)
					if (this.newAddress.length != 0) {
						if (this.schoolNameShow) {
							if(val.length != 0){
								this.getHkSchoolList(val)
							}else{  
								this.schoolNameList = [];
							}
						} else {
							this.schoolNameShow = true;
							this.schoolNameList = [];
						} 
					}
				}
			},
			showPickerschool(val){
				console.log(val)
			}
		},
		created() {
			this.getAddressList(); 
		},
		mounted() {
			document.addEventListener("click", this.handleClickOutside);
		},
		beforeUnmount() {//避免元素銷毀還繼續監聽
		    document.removeEventListener("click", this.handleClickOutside);
		},
		methods: {
			whatapp(){  
				this.home_mdevInit();
				window.open(this.whatUrl, "_blank");
			},
			onmobilePhoneSelect(action){ 
				this.from.code = action.text;
			},
			isNumber(value) {
				return typeof value === 'number';
			},
			// 電郵驗證
			validator_email(val) {
				const email =val.trim(); 
				return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(email);
			},
			toggleElement() { 
			    this.showPickerschool = !this.showPickerschool;
				console.log('1231231',this.showPickerschool)  
			},
			handleClickOutside(event) {
			  if (this.$refs.elementDQ && !this.$refs.elementDQ.contains(event.target)) {
				this.showPickerschool = false;
			  }
			},
			onselectDqBut(event,index){
				this.dqiSelectIndex = index;
				this.diquaddRess2 = event.data;
				this.dqiSelectIndex2 = 0;
				this.newAddress[0] = event.title;
				this.newAddress[1] = event.data[0]
			},
			onselectDqBut2(event,index){
				if(this.dqiSelectIndex != -1){
					this.dqiSelectIndex2 = index;
					this.newAddress[1] = event
				}else{
					this.$toast('請先選擇區域');
				}
				
			},
			onSexSelect(action) {
				this.sexIndex = action.id;
				this.from.sex = action.text;
			}, 
			// 分配组别
			onGradeSelect(action) {
				console.log(action);
				this.gradeIndex = action.id;
				this.from.grade = action.text;
	
				switch (action.text) {
					case 'K1':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K2':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K3':
						this.groupId = this.groups[1].code
						this.competitionGroup = this.groups[1].name
						break
					case 'P1':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P2':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P3':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P4':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P5':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'P6':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'S1':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S2':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S3':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					default:
						console.log('未匹配')
						this.groupId = ''
						this.competitionGroup = ''
						break
				}
				console.log('pc',this.groupId, this.competitionGroup)
			},


			zhiyin(e) {
				this.from.schoolName = e.schoolName;
				this.schoolNameShow = false;
				this.schoolNameList = [];
			},
			onAddressChange(picker, value, index) {
				picker.setColumnValues(1, this.cities[value[0]]);
				this.newAddress = value;
			},
			onAddressConfirm(e) {
				console.log(e)
				this.newAddress = e;
				this.showPickerschool = false;
			},
			getAddressList() {
				this.$axios
					.get(this.$api.getHkSchoolRegion)
					.then(res => {
						console.log(res)
						if (res.data.code == 0) {
							let data = res.data.data;
							this.diquaddRess = data;
							this.diquaddRess2 = data[0].data
							
							
							console.log(this.diquaddRess2)
							const cities = data.reduce((result, {
								title,
								data
							}) => {
								result[title] = data;
								return result;
							}, {});
							console.log(cities)
							this.cities = cities;
							this.diqu = [{
								values: Object.keys(cities)
							}, {
								values: cities['港島區域']
							}]
						}
					})
					.catch((res) => {
						console.log('失败', res)
					})
			},
			getHkSchoolList(e) {
				let params = {
					schoolRegion: this.newAddress[0],
					schoolRegion1: this.newAddress[1],
					schoolName: e
				}
				this.$axios({
						url: this.$api.getHkSchool,
						method: 'get',
						params: params
					})
					.then(res => {
						console.log(res)
						let data = res.data.rows
						if (res.data.code == 0) {
							this.schoolNameList = [];
							this.schoolNameList = data
						}
					})
					.catch((res) => {
						console.log('失败', res)
					})
			},
			validator_phone(val) {
				const phone = val
				return /^[0-9]*$/.test(phone)
			},
			// 验证码
			clickNumberCodeFnc() {
				let {
					code,
					mobilePhone
				} = this.from;
				console.log(code, mobilePhone)
				if (mobilePhone == null || mobilePhone == '') {
					this.$toast.fail('請檢查您的手機號')
					return;
				}

				if (!this.validator_phone(mobilePhone)) {
					this.$toast('手機號格式有誤 !')
					return
				}

				const phoneList = []
				phoneList.push(code + '-' + mobilePhone)
				const params = {
					appType: 12,
					verifyType: 9,
					phoneList,
					smsTemplateType: 10,
					signType: 1
				}

				this.$toast.loading({
					message: '正在獲取中...',
					forbidClick: true,
					duration: 0
				});

				this.$axios({
						url: this.$api.sendSmsForSecondCup,
						method: 'post',
						data: params
					})
					.then((response) => {
						console.log('response',response)
						if(response.data.code == 0){
							this.$toast.clear();
							this.$toast.success('驗證碼获取成功');
							let that = this
							clearInterval(that.timeIntinvel)
							that.CodeBln = false
							let index = 60

							that.timeIntinvel = setInterval(() => {
								index--
								if (index <= 0) {
									index = 0
									that.CodeBln = true
									that.codeMsg = '獲取驗證碼'
								} else {
									that.codeMsg = `${index}S`
								}
							}, 1000) 
						}else{
							this.$toast.fail('驗證碼获取失败');
						}
						
					})
					.catch((error) => {
						//请求失败
						this.$toast.clear();
						this.$toast.fail('error')
						console.log(error)
					})
			},
			getQueryString: function(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			// 表單
			Frombuttom() {
				// console.log("報名成功"); 
				let {
					username,
					sex,
					code,
					mobilePhone,
					mobileCode,
					grade,
					mail,
					school_address,
					schoolName,
				} = this.from;

				if (username == '' || username == null) {
					this.$toast.fail('請檢查參賽者姓名')
					return
				}
				if (sex == '請選擇參賽者性别' || sex == -1) {
					this.$toast.fail('請選擇參賽者性别')
					return
				}

				if (mobilePhone == '' || mobilePhone == null) {
					this.$toast.fail('請輸入參賽者電話')
					return
				}
				
				if (!this.validator_phone(mobilePhone)) {
					this.$toast('手機號格式有誤 !')
					return
				}

				if (mobileCode == '' || mobileCode == null) {
					this.$toast.fail('請輸入驗證碼')
					return
				}

				if (mail == '' || mail == null) {
					this.$toast.fail('請輸入電郵')
					return
				}
				
				if (!this.validator_email(mail)) {
					this.$toast('電郵格式有誤 !');
					return;
				}

				if (grade == '選擇參賽者年級' || grade == null) {
					this.$toast.fail('選擇參賽者年級')
					return
				}


				if (this.newAddress.length == 0) {
					this.$toast.fail('選擇學校地區')
					return
				}

				if (schoolName == '' || schoolName == null) {
					this.$toast.fail('請輸入就讀學校')
					return
				}

				const params = {
					studentName: username,
					sex: sex,
					phone: mobilePhone,
					grade: grade,
					prefix: code.replace(/\+/g, ''),
					email: mail,
					verifyCode: mobileCode,
					studentRegion: this.newAddress[0] + '>' + this.newAddress[1],
					currentSchool: schoolName,
					competitionGroup: this.competitionGroup,
					groupId: this.groupId,
					platform: !localStorage.getItem('c') ? null : localStorage.getItem('c')
				}

				console.log('提交', params)
				this.$toast.loading({
					message: '正在提交中...',
					forbidClick: true,
					duration: 0
				});

				this.$axios({
						url: this.$api.fromSave,
						method: 'post',
						data: params
					})
					.then((response) => {
						console.log(response)
						if (response.data.code == 0) {
							this.$toast.clear();
							this.$toast.success('提交成功');
							this.$router.replace('/successful')
						} else {
							this.$toast.clear();
							this.$toast.fail(response.data.msg)
						}
					})
					.catch((error) => {
						//请求失败
						this.$toast.clear();
						this.$toast.fail('error')
						console.log(error)
					})

				// this.$router.push("/successful");
			},
			onSexConfirm(e) {
				console.log(e);
				this.from.sex = e;
				this.showPicker = false;
			},
			sexBut() {
				this.showPicker = true;
			},
			schoolBut() {
				this.showPickerthree = true;
			},
			pickerPhoneBut() {
				this.showPickerTwo = true;
			},
			onPhoneConfirm(e) {
				console.log(e);
				this.from.code = e;
				this.showPickerTwo = false;
			},
			ongradeConfirm(e) {
				console.log(e);
				this.from.grade = e;
				this.showPickerthree = false;
				switch (this.from.grade) {
					case 'K1':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K2':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K3':
						this.groupId = this.groups[1].code
						this.competitionGroup = this.groups[1].name
						break
					case 'P1':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P2':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P3':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P4':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P5':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'P6':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'S1':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S2':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S3':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					default:
						console.log('未匹配')
						this.groupId = ''
						this.competitionGroup = ''
						break
				}
				console.log(this.groupId, this.competitionGroup)
			},
		},
	};
</script>

<style lang="scss" scoped>
	.views {
		width: 100%;
		height: 100vh;
		overflow-x: hidden;
		background: #ff782f;
		 

		.posters {
			width: 100%;
			display: flex;
			position: relative;

			img {
				width: 100%;
			}

			.posters_ewm {
			position: absolute;
			width: 1024px;
			left: 0;
			right: -10%;
			bottom: 0;
			top: 0;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: right; 

			.ewm {
				width: 161px;
				min-height: 189px;
				background: rgba(255, 255, 255, 0.63);
				border-radius: 16px 16px 16px 16px;
				padding: 8px;
				box-sizing: border-box;
				cursor: pointer;

				.ewm2 {
					width: 100%;
					height: 100%;
					padding: 6px;
					background: #fdcf1d;
					border-radius: 12px 12px 12px 12px;
					box-sizing: border-box;

						.ewm3 {
							width: 132px;
							height: 132px;
							background: #ffffff;
							border-radius: 8px 8px 8px 8px;
							margin-bottom: 6px;
							overflow: hidden;

							img {
							width: 100%;
							height: 100%;
							object-fit: contain;
							}
						}

						.ewm3_value {
							width: 100%;
							height: 40px;
							font-size: 16px;
							font-family: PingFang SC-Semibold, PingFang SC;
							font-weight: 600;
							color: #323232; 
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							background: url('~@/assets/image/icon/ewmbut.png') no-repeat;
							background-size: 100% 100%;
							box-shadow: inset 0px 1px 4px 0px rgba(234,138,7,0.48), inset 0px -5px 3px 0px rgba(255,227,129,0.57);
							vertical-align: middle; /* 将文本和图片垂直居中对齐 */

							img{
								width: 20px;
								height: 20px;
								margin-right: 4px;
								vertical-align: middle; /* 将图片和文本垂直居中对齐 */ 
							} 
						}
					}
				}
			}
		}

		.content {
			width: 100%;
			max-width: 944px;
			margin: auto;
			margin-top: 83px;
			margin-bottom: 30px;

			min-height: 795px;
			background: #ffffff;
			box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
			border-radius: 16px;

			padding-bottom: 26px;


			.contentFrom_titleBox {
				width: 100%;
				height: 52px;
				position: relative;

				.contentFrom_title {
					width: 265px;
					height: 52px;
					margin: auto;
					border-radius: 9px;
					background: url("~@/assets/image/icon/titlebg.png") no-repeat;
					background-size: 100% 100%;
					font-size: 20px;
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #ffffff;
					line-height: 23px;
					line-height: 52px;
					position: absolute;
					top: -47%;
					left: 0;
					right: 0;
					margin: auto;

					text-shadow: 1.5px 1.5px 0 #ff782f,
						-1.5px 1.5px 0 #ff782f,
						1.5px -1.5px 0 #ff782f,
						-1.5px -1.5px 0 #ff782f;
				}

			}

			.contentFrom_uls {
				padding: 30px 158px 0 158px;
				box-sizing: border-box;

				ul {
					width: 100%;
					background: #ffffff;

					li:last-child {
						border-bottom: none !important;
					}

					li {
						width: 100%;
						border-bottom: 1px solid #F2F2F2;
						;

						.list {
							width: 100%;
							height: 68px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.listLeft {
								font-size: 14px;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
								color: #333333;
							}

							.listPan {
								position: relative;

								.posText {
									position: absolute;
									height: 1px;
									bottom: 0;
									right: 0;
									min-width: 50%;
									margin: auto;

									ul {
										width: 100%;
										box-sizing: 0 1px 3px rgba(31, 35, 40, 0.12), 0 8px 24px rgba(66, 74, 83, 0.12);

										border: 1px solid #F2F2F2;


										li {
											height: 30px;
											// line-height: 30px;
											text-align: left;
											padding: 10px;
											box-sizing: border-box;
											cursor: pointer;
										}

										li:hover {
											background: #52A1FF;
											color: #ffffff
										}
									}
								}
							}

							.listRight {
								width: 70%;
								height: 100%;
								position: relative;

								input {
									width: 100%;
									height: 100%;
									font-size: 14px;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;
									color: #000000;
									border: none;
									text-align: right;
									background: none;
									padding: 0;
									box-sizing: border-box;
								}
								/* 隐藏步进器 */
								input[type="number"]::-webkit-inner-spin-button,
								input[type="number"]::-webkit-outer-spin-button {
								-webkit-appearance: none;
								margin: 0;
								}

								input[type="number"] {
								-moz-appearance: textfield; /* Firefox */
								}

								input::-webkit-input-placeholder {
									color: #CCCCCC;
								}

								/* 使用webkit内核的浏览器 */
								input:-moz-placeholder {
									color: #CCCCCC;
								}

								/* Firefox版本4-18 */
								input::-moz-placeholder {
									color: #CCCCCC;
								}

								/* Firefox版本19+ */
								input:-ms-input-placeholder {
									color: #CCCCCC;
								}

								/* IE浏览器 */
								
								.listRight_xuan {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 14px;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;
									cursor: pointer;

									.placeholderValue {
										color: #000000 !important;
									}

									.listRight_icon {
										margin-left: 10px;
									}

									.listRight_icontransform {
										transform: rotate(180deg);
									}


									
								}

								.listRight_sji {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 14px;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;

									.left {
										font-size: 14px;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #333333;
										height: 100%;
										display: flex;
										align-items: center;
										position: relative;
										padding-right: 0.32rem;
										box-sizing: border-box;

										span:nth-child(1) {
											font-size: 14px;
											font-family: PingFang SC-Regular, PingFang SC;
											font-weight: 400;
											color: #333333;
											margin-right: 10px;
										}

										.left_formTwo {
											transform: rotate(180deg);
										}
									}

									.left:after {
										content: " ";
										width: 1px;
										height: 20px;
										background: #CCCCCC;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										margin: auto;
									}

									.right {
										padding-left: 30px;
										box-sizing: border-box;
										width: 150px;
										height: 100%;
									}
								}

								.listRight_sji2 {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 14px;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;

									.right {
										font-size: 14px;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #333333;
										height: 100%;
										display: flex;
										align-items: center;
										position: relative;
										padding-right: 30px;
										box-sizing: border-box;
									}

									.right2 {
										font-size: 14px;
										font-family: PingFang SC-Semibold, PingFang SC;
										font-weight: 600;
										color: #52A1FF;
										display: flex;
										align-items: right;
										width: 79px;
										justify-content: right;
										cursor: pointer;
									}

									.right:after {
										content: " ";
										width: 1px;
										height: 20px;
										background: #CCCCCC;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										margin: auto;
									}
								}
							
							
								.selectBox{
									position: absolute;
									top: 100%;
									right:0;
									min-width: 200px;
									min-height: 20px;
									background: #ffffff;
									z-index: 99;
									padding: 10px; 
									border-radius: 10px;
									box-shadow: 0 2px 12px rgba(50,50,51,.12);
									display: flex;
									
									.selectBoxLeft{
										width: 49%;
										min-height: 30px;
										
										.selectBox_uls{
											width: 100%;
											height: 100%;
											overflow-y: hidden;
											font-size: 14px;
											
											.selectBox_lis{
												width: 100%;
												height: 30px;
												display: flex;
												align-items: center;
												white-space: nowrap;
											}
										}
									}
									
									.selectBox_xian{ 
										width: 1px;
										background: #cccccc;
										border-radius: 10px;
									}
									
									.selectBoxRight{
										width: 49%;
										min-height: 30px;
										max-height: 300px;
										margin-left: 10px; 

										.selectBox_uls{
											width: 100%;
											height: 100%;
											font-size: 14px;
											overflow-y: hidden;
											
											.selectBox_lis{
												width: 100%;
												height: 30px;
												display: flex;
												align-items: center;
												white-space: nowrap;
												 
											}
										}
									}
									
									.selectBox_lis{
										cursor: pointer;
									}
									
									.select_select{ 
										color: red;
									}
								}
							}
						}

						.list2 {
							width: 100%;

							.list2_uls {
								width: 100%;
								height: 68px;
								display: flex;
								align-items: center;
								justify-content: space-between;

								.listLeft {
									font-size: 14px;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;
									color: #333333;
								}

								.listRight {
									width: 70%;
									height: 100%;
									display: flex;

									input {
										width: 100%;
										height: 100%;
										font-size: 14px;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #000000;
										border: none;
										text-align: right;
										background: none;
										padding: 0;
										box-sizing: border-box;
									}
									
									/* 隐藏步进器 */
									input[type="number"]::-webkit-inner-spin-button,
									input[type="number"]::-webkit-outer-spin-button {
									-webkit-appearance: none;
									margin: 0;
									}

									input[type="number"] {
									-moz-appearance: textfield; /* Firefox */
									}

									input::-webkit-input-placeholder {
										color: #CCCCCC;
									}

									/* 使用webkit内核的浏览器 */
									input:-moz-placeholder {
										color: #CCCCCC;
									}

									/* Firefox版本4-18 */
									input::-moz-placeholder {
										color: #CCCCCC;
									}

									/* Firefox版本19+ */
									input:-ms-input-placeholder {
										color: #CCCCCC;
									}

									/* IE浏览器 */

									.listRight_xuan {
										width: 100%;
										height: 100%;
										display: flex;
										align-items: center;
										text-align: right;
										justify-content: flex-end;
										color: #CCCCCC;
										font-size: 14px;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										cursor: pointer;

										.placeholderValue {
											color: #000000 !important;
										}

										.listRight_icon {
											margin-left: 10px;
										}

										.listRight_icontransform {
											transform: rotate(180deg);
										}
									}
								}
							}

							.beizhu {
								width: 100%;
								font-size: 16px;
								line-height: 20px;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
								color: #e80202;
								margin-top: 8px;
								text-align: left;
								padding-bottom: 9px;
								box-sizing: border-box;
							}
						}
					}
				}

				.uls_beizhu {
					font-size: 16px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #e80202;
					line-height: 13px;
					text-align: center;
					margin-top: 16px;
				}
			}
			
			
			
			.promptInputValue {
				min-width: 100px;
				height: 68px;
				line-height: 68px;
				// padding-left: 13px;
				// box-sizing: border-box;
				// border: none;
				// background: #f5f5f5;
				// border-radius: 6px 6px 6px 6px;
				// font-size: 12px;
				// font-family: PingFang SC-Regular, PingFang SC;
			}
			
			
			
			.fromButtom {
				width: 343px;
				height: 48px;
				text-align: center;
				margin: auto;
				margin-top: 32px;
				cursor: pointer;

				img {
					width: 100%;
				}
			}
			.timeUp {
				width: 300px;
				height: 48px;
				margin: auto;
				margin-top: 32px;
				background: #d0d0d0;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				cursor: not-allowed;
				font-size: 20px;
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				line-height: 20px;
				box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
				border-radius: 24px;
			}
		}
	}

	.views::-webkit-scrollbar {
		display: none;
	}
</style>
